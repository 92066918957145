<template>
  <div>
    <!-- <CSBreadcrumb style="overflow: hidden"></CSBreadcrumb> -->
    <div class="filter-panel">
      <CSSelect
          style="width: 230px; margin-right: 10px;"
      >
        <el-date-picker
            v-model="startDate"
            :editable="false"
            format="yyyy-MM-dd HH:mm"
            placeholder="请选择开始时间"
            prefix-icon="el-icon-time"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
        ></el-date-picker>
      </CSSelect>
      <span
          style="
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 0 15px 0;
                    height: 2px;
                    width: 20px;
                    margin-right: 0;
                    background-color: #999;
                "
      >
            </span>
      <CSSelect
          style="margin-left: 10px; width: 230px; margin-right: 20px;"
      >
        <el-date-picker
            v-model="endDate"
            :editable="false"
            format="yyyy-MM-dd HH:mm"
            placeholder="请选择结束时间"
            prefix-icon="el-icon-time"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
        ></el-date-picker>
      </CSSelect>
      <CSSelect style="margin-right: 30px">
        <select
            v-model="sendType"
            :style="{
                        color: sendType != '' ? '#000' : '#999',
                    }"
        >
          <option value="">全部发送对象</option>
          <option
              v-for="(item, n) in SebdObjectList"
              :key="`${item}_${n}`"
              :value="item.id"
              style="color: #000;"
          >
            {{ item.name }}
          </option>
        </select>
      </CSSelect>

      <button class="getQuery" style="width: 50px; height: 30px; padding: 0;" @click="getQuestionnaire()">查询</button>
    </div>

    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button
                class="btn btn-primary sticky-right"
                @click="$router.push({ name: 'addQuestionnaire' })"
            >
              <svg
                  aria-hidden="true"
                  class="icon"
              >
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              添加问卷
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th class="text-center">添加时间</th>
            <th class="text-center">发送对象</th>
            <th class="text-center">发送条件</th>
            <th class="text-center">新注册用户发送</th>
            <th class="text-center">有效期</th>
            <th class="text-center">问卷名称</th>
            <th class="text-center">数据统计</th>
            <th class="text-center">操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr
              v-for="(item, index) in invitationList"
              :key="index"
          >
            <td class="text-center date-td">
              {{ item.createTime || '-' }}
            </td>
            <td class="text-center">
              <template v-if="item.sendType">
                {{ item.sendType | getSneTypeStr }}
              </template>
              <template v-else>-</template>
            </td>
            <td class="text-center">
                          {{ item | getTriggerType }}
            </td>
            <td class="text-center">
              {{
                (!item.newUserRegister) ? '不发送' : (item.newUserRegister == 1) ? '发送【停止发送时间：' + item.endTime + '】' : '不发送'
              }}
            </td>
            <td class="text-center">
              {{ item.validDays || '-' }}
            </td>
            <td class="text-center">
              {{ item.name || '-' }}
            </td>
            <td class="text-center">
                                    <span
                                        class="allow-click"
                                        @click="lookStatistics(item.id)"
                                    >查看</span
                                    >
            </td>
            <td class="text-center">
              <div class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul
                    class="dropdown-menu"
                    style="width: 88px;"
                >
                  <li v-if="!item.isEnable">
                    <a
                        style="width: 100%;"
                        @click="
                                                        openEnableModal(
                                                            1,
                                                            item.id
                                                        )
                                                    "
                    >
                      启用
                    </a>
                  </li>
                  <li v-else>
                    <a
                        style="width: 100%;"
                        @click="
                                                        openEnableModal(
                                                            0,
                                                            item.id
                                                        )
                                                    "
                    >
                      禁用
                    </a>
                  </li>
                  <li>
                    <a
                        style="width: 100%;"
                        @click="
                                                        deleteQuestionnaireModalVisible = true;
                                                        deleteQuestionnaireId =
                                                            item.id;
                                                    "
                    >
                      删除问卷
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination/>
    </div>
    <!-- 删除问卷弹出框 -->
    <CSDialog
        :dialogVisible="deleteQuestionnaireModalVisible" dialog-confirm-btn-text="确定"
        dialog-header-class=" " dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="deleteQuestionnaireModalVisible = false"
        @onConfirm="deleteQuestionnaire"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center;"
      >
        确定删除吗?
      </div>
    </CSDialog>
    <!-- 禁用弹窗 -->
    <CSDialog
        :dialogVisible="enablequestionnaireModalInfo.visible" dialog-confirm-btn-text="确定"
        dialog-header-class=" " dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="enablequestionnaireModalInfo.onClose"
        @onConfirm="changeQuestionnaireState"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center;"
      >
        {{ enablequestionnaireModalInfo.title }}
      </div>
    </CSDialog>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination";
import CSDialog from "@/components/common/CSDialog";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import {adminQueryUrl, changeEnableQuestionnaireUrl, deleteQuestionnaireUrl,} from "@/requestUrl";
import dayjs from "dayjs";
import {QUESTIONNAIRE_SEND_TYPE, QUESTIONNAIRE_SEND_TYPE_STR, TRIGGER_SEND_QUESTIONNAIRE_TYPE,} from "@/constant";
import CSTable from "@/components/common/CSTable";

export default {
  name: "QuestionnaireManage",
  components: {
    CSTable,
    CSSelect,
    Pagination,
    CSDialog,
    CSBreadcrumb
  },
  created() {
    window.addEventListener("keydown", this.getQuestionnaireDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.getQuestionnaireDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.getQuestionnaireDown);
  },
  data() {
    return {
      filterHeight: 0,
      // 启用/禁用弹窗
      enablequestionnaireModalInfo: {
        visible: false,
        title: "",
        data: {},
        onClose: () => {
          this.enablequestionnaireModalInfo.visible = false;
        },
      },
      deleteQuestionnaireModalVisible: false, //删除问卷弹出框的显示与隐藏
      deleteQuestionnaireId: null,
      // startDate: dayjs()
      //   .subtract(30, "day")
      //   .format("YYYY-MM-DD HH:mm:ss"),
      // endDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      triggerSendType: {
        [TRIGGER_SEND_QUESTIONNAIRE_TYPE.DEED]: "行为",
        [TRIGGER_SEND_QUESTIONNAIRE_TYPE.TIMING]: "定时发送",
        [TRIGGER_SEND_QUESTIONNAIRE_TYPE.REGISTER_DATE]:
            "按注册时间发送",
        [TRIGGER_SEND_QUESTIONNAIRE_TYPE.SIGN_CONTRACT_DATE]:
            "按合同签约时间发送",
      },
      startDate:
          dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00",
      endDate: dayjs().format("YYYY-MM-DD") + " 23:59",
      sendType: "",
      SebdObjectList: [
        {id: QUESTIONNAIRE_SEND_TYPE.USER, name: "楼内用户"},
        {
          id: QUESTIONNAIRE_SEND_TYPE.VISITER,
          name: "访客(含未知身份)",
        },
        {
          id: QUESTIONNAIRE_SEND_TYPE.ENTERPRISE_DOCKING_PERSON,
          name: "企业对接人",
        },
        {id: QUESTIONNAIRE_SEND_TYPE.OWNER, name: "业主"},
        {id: QUESTIONNAIRE_SEND_TYPE.EMPLOYEE, name: "普通员工"},
        {
          id: QUESTIONNAIRE_SEND_TYPE.DEPARTMENT_MANAGER,
          name: "部门主管",
        },
        {
          id: QUESTIONNAIRE_SEND_TYPE.PROPERTY_MANAGER,
          name: "物业经理",
        },
      ],
      //   查询帖子返回来的数据
      invitationList: [],
      name: "", //问卷名称
      validDays: null, //有效期
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  filters: {
    getTriggerType(quesitonnaire) {
      let {type, condition} = quesitonnaire;
      condition = JSON.parse(condition);
      const deedTypeStr = {
        1: `购买${condition.frequency || "x"}次商品且已签收`,
        2: `购买${condition.frequency || "x"}次上门服务且已核销消费码`,
        3: `已签约${condition.frequency || "x"}次企业服务`,
        4: `已使用${condition.frequency || "x"}次共享会议室`,
      };
      switch (type) {
        case TRIGGER_SEND_QUESTIONNAIRE_TYPE.DEED:
          return `${deedTypeStr[condition.deedType]}发送`;
        case TRIGGER_SEND_QUESTIONNAIRE_TYPE.TIMING:
          return `${condition.sendDate}发送`;
        case TRIGGER_SEND_QUESTIONNAIRE_TYPE.REGISTER_DATE:
          return `注册${condition.day}天发送`;
        case TRIGGER_SEND_QUESTIONNAIRE_TYPE.SIGN_CONTRACT_DATE:
          return `签约${condition.day}天发送`;
        default:
          return "立即发送";
      }
    },
    getSneTypeStr(types) {
      return JSON.parse(types)
          .map((type) => {
            return QUESTIONNAIRE_SEND_TYPE_STR[type];
          })
          .join("\n");
    },
  },
  mounted() {
    this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
      this.pageParams.pageNo = _currentPage;
      this.getQuestionnaire(_currentPage);
    })
    this.getQuestionnaire();
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    window.addEventListener('resize', () => {
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    })
  },
  /* activated() {
       this.getQuestionnaire();
   },*/
  methods: {
    getQuestionnaireDown(e) {
      if (e.keyCode == 13) {
        this.getQuestionnaire();
      }
    },
    /**
     * 打开问卷更改弹窗
     * @param {Number} isEnable 0：禁用 1：启用
     * @param {Number} id 问卷id
     *  */
    openEnableModal(isEnable, id) {
      this.enablequestionnaireModalInfo.data = {
        isEnable,
        id,
      };
      this.enablequestionnaireModalInfo.visible = true;
      this.enablequestionnaireModalInfo.title = `确定${
          isEnable ? "启用" : "禁用"
      }吗?`;
    },
    // 改变问卷启用禁用状态
    changeQuestionnaireState() {
      const {id, isEnable} = this.enablequestionnaireModalInfo.data;
      this.$fly
          .post(changeEnableQuestionnaireUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            id,
            isEnable,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.$vc.toast(`${isEnable ? "启用" : "禁用"}成功`);
            this.enablequestionnaireModalInfo.visible = false;
            this.getQuestionnaire();
          });
    },
    // 删除问卷
    deleteQuestionnaire() {
      this.$fly
          .post(deleteQuestionnaireUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            id: this.deleteQuestionnaireId,
          })
          .then((res) => {
            if (res.code !== 0) {
              reutrn;
            }
            this.$vc.toast("删除成功");
            this.deleteQuestionnaireModalVisible = false;
            this.deleteQuestionnaireId = null;
            this.getQuestionnaire();
          });
    },
    // 后台查询问卷接口
    getQuestionnaire(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(adminQueryUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            startDate: this.startDate, //查询开始时间 必填
            endDate: this.endDate, //查询结束时间 必填
            sendType: this.sendType, //发送对象 1.楼内用户;2.访客(含未知身份);3.企业对接人4.业主;5.普通员工;6.部门主管;7.物业经理
          })
          .then((res) => {
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.length,
                currentPage: pageNo,
                pageSize: pageSize,
              })
            }
            this.invitationList = res.data.splice((pageNo - 1) * pageSize, pageSize);
            // TODO:不能加pageNo和pageSize;
            // 分页数据需要截取数组显示;
          });
    },
    // 查看数据统计
    lookStatistics(id) {
      this.$router.push({path: "questionnaire/log", query: {id}});
    },
  },
};
</script>
<style scoped>
.getQuery {
  width: 50px;
  height: 30px;
  background-color: #1ab394;
  border: 0;
  color: #fff;
  border-radius: 4px;
}

.typeinput {
  width: 220px;
  display: inline-block;
  height: 28px;
  border-radius: 3px;
  padding: 0 12px;
  margin: 0 30px;
}

.tabel_body td {
  vertical-align: middle !important;
  padding: 0 !important;
}

.look_Statistics {
  color: #1dafff;
}

.look_Statistics:hover {
  text-decoration: underline;
}
</style>
